import type { AxiosRequestConfig } from 'axios';

import type {
  MsApiResponse,
} from '../types/api';
import type { BuyerUserMapping } from '../types/mappings';
import BaseResource from './base';
import { MsApiEndPoints } from './endPoints';
import { MedusaPgcCorpShippingOptionsMapping } from '@prisma/client';

export class MedusaPgcCorpShippingOptionsMappingResource extends BaseResource {

  list(options?: AxiosRequestConfig): Promise<MsApiResponse<MedusaPgcCorpShippingOptionsMapping[]>> {
    return this.client.get<BuyerUserMapping, MsApiResponse<MedusaPgcCorpShippingOptionsMapping[]>>(
      `${MsApiEndPoints.medusaPgcCorpShippingOptionsMappings.list()}`,
      options
    );
  }

}
