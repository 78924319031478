import type { InternalAxiosRequestConfig } from 'axios';
import { isServer } from '../client';

/**
 * Adds an Authorization header with a Bearer token to requests if the authentication cookie is available
 */
export async function browserIdInterceptor<T = unknown>(config: InternalAxiosRequestConfig<T>) {
  let browserId;
  if (isServer) {
    const { cookies } = await import('next/headers');
    browserId = cookies().get('browserId')?.value;
  } else {
    browserId = document.cookie.replace(/(?:(?:^|.*;\s*)browserId\s*=\s*([^;]*).*$)|^.*$/, '$1');
  }

  if (browserId) {
    config.headers['x-browser-id'] = browserId;
  }
  return config;
}
