import type { ClassValue } from 'clsx';
import { clsx } from 'clsx';
import { twMerge as twMergeOriginal } from 'tailwind-merge';

/**
 * Utility function to more easily use classNames on an element in jsx.
 * There is no need to write "className={}" all the time
 *
 * Usage:
 * <div {...cnx('my-class')} />
 */
export function cnx(...args: ClassValue[]) {
  return {
    className: twMerge(clsx(args)),
  };
}

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}

export function twMerge(...args: ClassValue[]) {
  return twMergeOriginal(clsx(args));
}
