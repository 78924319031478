import type { AxiosInstance, CreateAxiosDefaults } from 'axios';
import axios, { AxiosError } from 'axios';
import mem from 'mem';
import {
  internalAdminAuthInterceptor,
  merchantAdminAuthInterceptor,
  portalAuthInterceptor,
} from './axios-interceptors/authInterceptor';
import { browserIdInterceptor } from './axios-interceptors/browserIdInterceptor';
import { logInterceptor } from './axios-interceptors/logInterceptor';
import { refreshTokenInterceptor } from './axios-interceptors/refreshTokenInterceptor';
import { refreshTokenSSRInterceptor } from './axios-interceptors/refreshTokenSSRInterceptor';
import { requestIdInterceptor } from './axios-interceptors/requestIdInterceptor';
import { sessionIdInterceptor } from './axios-interceptors/sessionIdInterceptor';
import type { AxiosResponseWithError, MsApiErrorResponse } from './types/api';
import { createBearerFromToken } from './utils/auth';

export const isServer = typeof window === 'undefined';

export type MsHttpClientOptions = {
  errorInterceptor?: boolean;
  portalAuthInterceptor?: boolean;
  internalAdminAuthInterceptor?: boolean;
  merchantAdminAuthInterceptor?: boolean;
  logInterceptor?: boolean;
  refreshTokenInterceptor?: boolean;
  refreshTokenSSRInterceptor?: boolean;
  browserIdInterceptor?: boolean;
  sessionIdInterceptor?: boolean;
  requestIdInterceptor?: boolean;
  projectPrefix?: string;
  systemPrefix?: string;
};

let currentConfig: CreateAxiosDefaults;

export function createMsHttpClient(config: CreateAxiosDefaults = {}, options?: MsHttpClientOptions) {
  const httpClient = axios.create({
    baseURL: 'http://localhost:8060/api/v1',
    withCredentials: true,
    ...config,
  });

  currentConfig = config;

  // register log interceptor
  if (options?.logInterceptor !== false) {
    httpClient.interceptors.request.use(logInterceptor);
  }

  // register auth interceptor
  if (options?.portalAuthInterceptor === true) {
    httpClient.interceptors.request.use(portalAuthInterceptor);
  }

  // register auth interceptor
  if (options?.internalAdminAuthInterceptor === true) {
    httpClient.interceptors.request.use(internalAdminAuthInterceptor);
  }

  // register auth interceptor
  if (options?.merchantAdminAuthInterceptor === true) {
    httpClient.interceptors.request.use(merchantAdminAuthInterceptor);
  }

  // register refresh token interceptor
  if (options?.refreshTokenInterceptor !== false) {
    httpClient.interceptors.response.use(response => response, refreshTokenInterceptor);
  }

  // register refresh token interceptor for SSR
  if (options?.refreshTokenSSRInterceptor === true) {
    httpClient.interceptors.response.use(response => response, refreshTokenSSRInterceptor);
  }

  // register error interceptor
  if (options?.errorInterceptor === true) {
    httpClient.interceptors.response.use(response => response, errorInterceptor);
  }

  // register browser id interceptor
  if (options?.browserIdInterceptor !== false) {
    httpClient.interceptors.request.use(browserIdInterceptor);
  }

  // register session id interceptor
  if (options?.sessionIdInterceptor !== false) {
    httpClient.interceptors.request.use(sessionIdInterceptor);
  }

  // register request id interceptor
  if (options?.requestIdInterceptor !== false) {
    httpClient.interceptors.request.use(config =>
      requestIdInterceptor(config, options?.projectPrefix, options?.systemPrefix)
    );
  }

  return httpClient;
}

/**
 * Intercept thrown errors and return them as a response property instead
 */
export async function errorInterceptor(error: unknown) {
  if (error instanceof AxiosError) {
    console.log('SSR Axios Error', error.message);
    console.log('SSR Axios Error Response', error.response);
    const serverError: MsApiErrorResponse = error.response?.data || error.message;
    return { error: serverError, data: undefined } as AxiosResponseWithError<unknown>;
  }
  return;
}

// use axios public instance to get a new access token
export async function refreshAccessToken(userId?: string) {
  return await axios.post(currentConfig.baseURL + '/auth/refresh', null, {
    withCredentials: true,
    headers: {
      'x-user-id': userId,
    },
  });
}

export async function refreshSSRAccessToken(refreshToken: string, userId?: string) {
  return await axios.post(currentConfig.baseURL + '/auth/refresh', null, {
    withCredentials: true,
    headers: {
      'x-user-id': userId,
      Refresh: createBearerFromToken(refreshToken),
    },
  });
}

// if twenty http requests are made and all of them get a 401 (Unauthorized)
// we don't want the token to be refreshed twenty times, so memoize the function for 10 seconds
const maxAge = 10000;
export const refreshAccessTokenMemoized = mem(refreshAccessToken, { maxAge });

export type HttpClient = AxiosInstance;
