import type { InternalAxiosRequestConfig } from 'axios';
import axios, { AxiosError } from 'axios';
import cookie from 'cookie';
import { refreshSSRAccessToken } from '../client';
import type { AccessTokenInfo } from '../types/api';
import type { UserEntity } from '../types/users';

/**
 * This Interceptor for the SSR client is supposed to use the refresh token to renew an accesstoken.
 * Then use the new accesstoken to retrieve the data and set the new cookies on the client.
 *
 * Refreshing the tokens and using the new acceest token to get data is working but settings the cookies on the client does not seem to work.
 */
export async function refreshTokenSSRInterceptor(error: Error) {
  // console.log('refreshTokenSSRInterceptor', error.message);

  if (error instanceof AxiosError) {
    const config = error.config as InternalAxiosRequestConfig & { sent?: boolean };

    // const authCookie = Cookies.get('auth');
    const { cookies } = await import('next/headers');
    const authCookie = cookies().get('auth')?.value;
    const refreshToken = cookies().get('refresh')?.value;

    const userCookie = cookies().get('user')?.value;

    let userFromCookie: Partial<UserEntity> = {};
    if (userCookie) {
      userFromCookie = JSON.parse(userCookie) as UserEntity;
    }

    // console.log('authCookie', authCookie);
    // console.log('refreshToken', !!refreshToken);

    // stop here if we don't have an auth cookie
    // the authCookie is not the access token and is not the refresh token
    // it's just a cookie that indicates when the access token expires
    // here we are just using it to see if we should bother with trying to refresh the token or not
    if (!authCookie) return Promise.reject(error);
    if (!refreshToken) return Promise.reject(error);

    // if we have an authCookie check it wasn't expired longer than the refresh token is valid
    const refreshTokenMaxAge = 604800 * 1000;

    const authCookieObj = JSON.parse(authCookie) as AccessTokenInfo;

    const tokenCreationTime = new Date(0);
    tokenCreationTime.setUTCSeconds(authCookieObj.iat);

    const refreshTokenExpireTime = new Date(0);
    refreshTokenExpireTime.setUTCSeconds(authCookieObj.iat);

    refreshTokenExpireTime.setMilliseconds(tokenCreationTime.getMilliseconds() + refreshTokenMaxAge);

    const isExpired = refreshTokenExpireTime.getTime() - tokenCreationTime.getTime() < 0;

    // console.log('isExpired', isExpired, error.response?.status, config.sent);

    if (isExpired) return Promise.reject(error);

    if ((error.response?.status === 403 || error.response?.status === 401) && !config?.sent) {
      config.sent = true;

      try {
        const response = await refreshSSRAccessToken(refreshToken, userFromCookie?.id);

        // // set the cookies from the refresh token call on the axios response that will be sent to the client
        // config.headers['set-cookie'] = response.headers['set-cookie']?.join(';');

        const setCookies = response.headers['set-cookie']?.join(';');

        const responseCookies = cookie.parse(setCookies || '');

        console.log('responseCookies', responseCookies);

        // // console.log('responseCookies.authentication', responseCookies.authentication);
        // // console.log('responseCookies.refresh', responseCookies.refresh);
        // // console.log('responseCookies.auth', responseCookies.auth);

        // Cookies.set('authentication', responseCookies.authentication);
        // Cookies.set('refresh', responseCookies.refresh);
        // Cookies.set('auth', responseCookies.auth);

        // // use the new access token we got from the refresh token call as the Authorization on the request that will be made on the server
        // config.headers['Authorization'] = createBearerFromToken(responseCookies.authentication);

        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + responseCookies.refresh;
        axios.defaults.headers.common['Auth'] = responseCookies.auth;
      } catch (err) {
        const error = err as AxiosError;
        console.error('refreshAccessToken response err', error.code, error.message);
      }

      return axios(config);
    }
  }
  return Promise.reject(error);
}
