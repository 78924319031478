import type { InternalAxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { isServer } from '../client';

/**
 * Adds a Request id header which is unique per request
 */
export async function requestIdInterceptor<T = unknown>(
  config: InternalAxiosRequestConfig<T>,
  projectPrefix?: string,
  systemPrefix?: string
) {
  if (!config.headers['x-request-id']) {
    let slug;
    if (isServer) {
      const { cookies } = await import('next/headers');
      slug = cookies().get('slug')?.value;
    } else {
      slug = document.cookie.replace(/(?:(?:^|.*;\s*)slug\s*=\s*([^;]*).*$)|^.*$/, '$1');
    }
    config.headers['x-request-id'] = `${projectPrefix}-${systemPrefix}${slug ? `-${slug}` : ''}-${uuidv4()}`;
  }
  return config;
}
