import type { AxiosRequestConfig } from 'axios';
import type { HttpClient } from '../client';
import type { AxiosResponseWithError, PagedDataSource, SearchParams } from '../types/api';

export default class BaseResource {
  public client: HttpClient;

  constructor(client: HttpClient) {
    this.client = client;
  }
}

export type GetResource<ResourceType> = {
  getBy?(
    searchParams?: Partial<ResourceType>,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<ResourceType>>;
  get(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<ResourceType>>;
  list(
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<PagedDataSource<ResourceType>>>;
};

export type CrudResource<ResourceType> = {
  create(payload: unknown, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<ResourceType>>;
  update(id: string, payload: unknown, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<ResourceType>>;
  delete(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<boolean>>;
};
