import { isBrowser } from './environment';

/**
 * Abstraction around local/session storage with extra functionalities.
 *
 * - supports settings keys with an expiration date
 */
export class Storage {
  constructor(private storage: globalThis.Storage) {}

  /**
   * Get a value from storage by key.
   *
   * @param {string} key - the key to retrieve the value for
   * @return {string | undefined} the value associated with the key, or undefined if not found
   */
  public get(key: string): string | undefined {
    if (!isBrowser) return undefined;

    return this.storage.getItem(key) || undefined;
  }

  /**
   * Retrieves JSON data from the specified key.
   *
   * @param {string} key - the key to retrieve the JSON data
   * @return {T | undefined} the JSON data if found, otherwise undefined
   */
  public getJSON<T>(key: string): T | undefined {
    const str = this.get(key);
    if (!str || str === 'undefined') return;
    return JSON.parse(str);
  }

  /**
   * Set the key-value pair in the storage if running in a browser environment.
   *
   * @param {string} key - the key for the storage item
   * @param {string} value - the value to be stored
   * @return {void}
   */
  public set(key: string, value: string): void {
    if (!isBrowser) return;

    this.storage.setItem(key, value);
  }

  /**
   * Sets a JSON value in the local storage.
   *
   * @param {string} key - the key for the local storage
   * @param {unknown} value - the value to be stored in the local storage
   * @return {void}
   */
  public setJSON(key: string, value: unknown): void {
    if (!isBrowser) return;
    if (!value) {
      // if no value was passed or it is undefined then just clear out the localstorage key
      return this.remove(key);
    }

    this.set(key, JSON.stringify(value));
  }

  /**
   * Remove the item with the specified key from the storage, if running in a browser environment.
   *
   * @param {string} key - the key of the item to be removed
   * @return {void}
   */
  public remove(key: string): void {
    if (!isBrowser) return;
    this.storage.removeItem(key);
  }

  /**
   * Storing items with expiry time
   *
   * @param key   - unique key
   * @param value - value to be stored
   * @param ttl   - time to live in seconds
   */
  public setWithExpiry(key: string, value: string, ttl: number) {
    if (!isBrowser) return;

    const now = Date.now();
    const item = {
      value,
      expiry: now + ttl * 1000,
    };

    this.setJSON(key, item);
  }

  /**
   * Retrieves the value of a key that was stored with an expiry.
   *
   * @param key - unique key
   */
  public getWithExpiry(key: string) {
    if (!isBrowser) return;

    const itemString = this.get(key);

    if (!itemString) return;

    const item = JSON.parse(itemString);
    const currentTime = new Date();

    if (currentTime.getTime() > item.expiry) {
      this.storage.removeItem(key);
      return;
    }
    return item.value;
  }
}
