import type { AxiosRequestConfig } from 'axios';

import type {
  MerchantBusinessCreatePayload,
  MerchantBusinessUpdatePayload,
  MerchantBusinessEntity as MerchantEntity,
} from '../../types/merchants';

import type {
  AxiosResponseWithError,
  FilePayload,
  MsApiResponse,
  PagedDataSource,
  SearchParams,
} from '../../types/api';
import type { BuyerBusinessEntity, BuyerBusinessSettings, LinkUserToBusinessPayload } from '../../types/buyers';
import type { UserEntity } from '../../types/users';
import type { CrudResource, GetResource } from '../base';
import BaseResource from '../base';
import { MsAdminApiEndPoints } from './endPoints';

export class MerchantsResource
  extends BaseResource
  implements GetResource<MerchantEntity>, CrudResource<MerchantEntity>
{
  /**
   * Retrieves a portal by matching field.
   */
  getBy(): Promise<AxiosResponseWithError<MerchantEntity>> {
    throw new Error('Method not implemented.');
  }

  /**
   * Retrieves a merchant by merchant id.
   */
  get(idOrName: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<MerchantEntity>> {
    return this.client.get<MerchantEntity, MsApiResponse<MerchantEntity>>(
      MsAdminApiEndPoints.merchants.get(idOrName),
      options
    );
  }

  /**
   * Retrieves a merchant by merchant id.
   */
  getByBusinessId(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<MerchantEntity>> {
    return this.client.get<MerchantEntity, MsApiResponse<MerchantEntity>>(
      MsAdminApiEndPoints.merchants.getByBusinessId(id),
      options
    );
  }

  /**
   * Retrieve all merchants.
   */
  list(
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<PagedDataSource<MerchantEntity>>> {
    return this.client.get<MerchantEntity[], MsApiResponse<PagedDataSource<MerchantEntity>>>(
      MsAdminApiEndPoints.merchants.list(),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Create a merchant.
   */
  create(
    payload: MerchantBusinessCreatePayload,
    options?: AxiosRequestConfig<MerchantBusinessCreatePayload>
  ): Promise<AxiosResponseWithError<MerchantEntity>> {
    return this.client.post<MerchantEntity, MsApiResponse<MerchantEntity>, MerchantBusinessCreatePayload>(
      MsAdminApiEndPoints.merchants.list(),
      payload,
      options
    );
  }

  /**
   * Update a merchant's details.
   */
  update(
    id: string,
    payload: MerchantBusinessUpdatePayload,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<MerchantEntity>> {
    return this.client.patch<MerchantEntity, MsApiResponse<MerchantEntity>, MerchantBusinessUpdatePayload>(
      MsAdminApiEndPoints.merchants.get(id),
      payload,
      options
    );
  }

  /**
   * Delete a merchant.
   */
  delete(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<boolean>> {
    return this.client.delete(MsAdminApiEndPoints.merchants.get(id), options);
  }

  /**
   * Retrieve true if merchant exists by merchant email.
   */
  existsByEmail(email: string, options?: AxiosRequestConfig) {
    return this.client.get<MerchantEntity, MsApiResponse<MerchantEntity>>(
      MsAdminApiEndPoints.merchants.existsByEmail(email),
      options
    );
  }

  /**
   * Retrieve merchants's business profile by merchant id
   */
  getMerchantProfile(id: string, options?: AxiosRequestConfig) {
    return this.client.get<MerchantEntity, MsApiResponse<MerchantEntity>>(
      MsAdminApiEndPoints.merchants.profile(id),
      options
    );
  }

  /**
   * Retrieves a merchant by slug.
   */
  getBySlug(slug: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<MerchantEntity>> {
    return this.client.get<MerchantEntity, MsApiResponse<MerchantEntity>>(
      MsAdminApiEndPoints.merchants.getBySlug(slug),
      options
    );
  }

  /**
   * upload a merchant's logo
   */
  async uploadLogo(merchantId: string, payload: FilePayload) {
    payload.id = payload.id || 'imageUrl';

    await this.client.post<string, MsApiResponse<string>>(
      MsAdminApiEndPoints.merchants.logo(merchantId),
      { ...payload, id: payload.id },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return payload.id;
  }

  /**
   * Retrieve all users for a merchant.
   */
  listUsers(
    merchantId: string,
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<PagedDataSource<UserEntity>>> {
    return this.client.get<UserEntity[], MsApiResponse<PagedDataSource<UserEntity>>>(
      MsAdminApiEndPoints.merchants.listUsers(merchantId, searchParams),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Retrieve all buyers for a merchant.
   */
  listBuyers(
    merchantId: string,
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<PagedDataSource<BuyerBusinessEntity>>> {
    return this.client.get<UserEntity[], MsApiResponse<PagedDataSource<BuyerBusinessEntity>>>(
      MsAdminApiEndPoints.merchants.listBuyers(merchantId, searchParams),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Retrieve merchant buyer settings
   */
  getBuyerSettings(
    merchantId: string,
    businessId: string,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<BuyerBusinessSettings>> {
    return this.client.get<BuyerBusinessSettings, MsApiResponse<BuyerBusinessSettings>>(
      MsAdminApiEndPoints.merchants.buyerSettings(merchantId, businessId),
      options
    );
  }

  /**
   * Update a merchant's buyer settings
   */
  updateBuyerSettings(
    merchantId: string,
    businessId: string,
    payload: Partial<BuyerBusinessSettings>,
    options?: AxiosRequestConfig<BuyerBusinessSettings>
  ): Promise<MsApiResponse<boolean>> {
    return this.client.post<Partial<BuyerBusinessSettings>, MsApiResponse<boolean>, Partial<BuyerBusinessSettings>>(
      MsAdminApiEndPoints.merchants.buyerSettings(merchantId, businessId),
      payload,
      options
    );
  }

  /**
   * delete merchant logo
   */
  async deleteLogo(merchantId: string, options?: AxiosRequestConfig<string>) {
    return this.client.delete<string, MsApiResponse<boolean>>(MsAdminApiEndPoints.merchants.logo(merchantId), options);
  }

  /**
   * Link a user to a businees (creates a mapping)
   */
  linkUserToBusiness(
    payload: LinkUserToBusinessPayload,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<unknown>> {
    return this.client.post<unknown[], MsApiResponse<unknown>>(
      MsAdminApiEndPoints.merchants.linkUserToBusiness(),
      payload,
      options
    );
  }
}
