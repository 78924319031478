import type { InternalAxiosRequestConfig } from 'axios';

/**
 * Adds an Authorization header with a Bearer token to requests if the authentication cookie is available
 */
export async function logInterceptor<T = unknown>(config: InternalAxiosRequestConfig<T>) {
  console.info(`Axios Request: ${config.baseURL}${config.url}`, config.headers);

  return config;
}
