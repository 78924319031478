import type { AxiosRequestConfig } from 'axios';

import type { AxiosResponseWithError, MsApiResponse } from '../types/api';
import type { PortalEntity } from '../types/portals';
import BaseResource from './base';
import { MsApiEndPoints } from './endPoints';

export class MerchantResource extends BaseResource {
  /**
   * Retrieves a portal by portal slug.
   */
  list(options?: AxiosRequestConfig): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.get<PortalEntity, MsApiResponse<PortalEntity>>(MsApiEndPoints.buyers.list(), options);
  }

  /**
   * Retrieves a portal by portal slug.
   */
  get(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.get<PortalEntity, MsApiResponse<PortalEntity>>(MsApiEndPoints.buyers.get(id), options);
  }
}
