import type { AxiosRequestConfig } from 'axios';

import type { AxiosResponseWithError, FilePayload, MsApiResponse, SearchParams } from '../../types/api';
import type { EmailAsset } from '../../types/emailTemplate';
import BaseResource from '../base';
import { MsAdminApiEndPoints } from './endPoints';

export class EmailAssetsResource extends BaseResource {
  /**
   * Retrieve all email assets.
   */
  list(searchParams?: Partial<SearchParams>, options?: AxiosRequestConfig): Promise<MsApiResponse<EmailAsset[]>> {
    return this.client.get<EmailAssetsResource[], MsApiResponse<EmailAsset[]>>(MsAdminApiEndPoints.emailAssets.list(), {
      params: searchParams,
      ...options,
    });
  }

  /**
   * Upload an email asset
   */
  async upload(payload: FilePayload): Promise<string> {
    await this.client.post<string, MsApiResponse<string>>(MsAdminApiEndPoints.emailAssets.upload(), payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return payload.id || '-';
  }

  /**
   * Delete an email asset
   */
  delete(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<boolean>> {
    return this.client.delete<string, MsApiResponse<boolean>>(MsAdminApiEndPoints.emailAssets.delete(id), options);
  }
}
