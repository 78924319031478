import BaseResource from '../base';
import { BrandsResource } from './brands';
import { BuyersResource } from './buyers';
import { EmailAssetsResource } from './emailAssets';
import { EmailTemplateResource } from './emailTemplates';
import { MerchantsResource } from './merchants';
import { PortalsResource } from './portals';
import { AdminUsersResource } from './users';

export * from './brands';
export * from './endPoints';
export * from './merchants';
export * from './portals';
export * from './users';

export class Admin extends BaseResource {
  public users = new AdminUsersResource(this.client);
  public merchant = new MerchantsResource(this.client);
  public brand = new BrandsResource(this.client);
  public portal = new PortalsResource(this.client);
  public buyer = new BuyersResource(this.client);
  public emailTemplates = new EmailTemplateResource(this.client);
  public emailAssets = new EmailAssetsResource(this.client);
}
