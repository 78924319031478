import type { AxiosRequestConfig } from 'axios';
import type {
  AxiosResponseWithError,
  FilePayload,
  MsApiResponse,
  PagedDataSource,
  SearchParams,
} from '../../types/api';
import type { PortalCreatePayload, PortalEntity, PortalUpdatePayload } from '../../types/portals';
import type { CrudResource, GetResource } from '../base';
import BaseResource from '../base';
import { MsAdminApiEndPoints } from './endPoints';

export class PortalsResource extends BaseResource implements GetResource<PortalEntity>, CrudResource<PortalEntity> {
  /**
   * Retrieves a portal by matching field.
   */
  getBy(): Promise<AxiosResponseWithError<PortalEntity>> {
    throw new Error('Method not implemented.');
  }

  /**
   * Retrieves a portal by portal id.
   */
  get(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.get<PortalEntity, MsApiResponse<PortalEntity>>(MsAdminApiEndPoints.portals.get(id), options);
  }

  /**
   * Retrieve all portals.
   */
  list(
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<PagedDataSource<PortalEntity>>> {
    return this.client.get<PortalEntity[], MsApiResponse<PagedDataSource<PortalEntity>>>(
      MsAdminApiEndPoints.portals.list(),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Create a portal.
   */
  create(
    payload: PortalCreatePayload,
    options?: AxiosRequestConfig<PortalCreatePayload>
  ): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.post<PortalEntity, MsApiResponse<PortalEntity>, PortalCreatePayload>(
      MsAdminApiEndPoints.portals.list(),
      payload,
      options
    );
  }

  /**
   * Update a portal's details.
   */
  update(
    id: string,
    payload: PortalUpdatePayload,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.patch<PortalEntity, MsApiResponse<PortalEntity>, PortalUpdatePayload>(
      MsAdminApiEndPoints.portals.get(id),
      payload,
      options
    );
  }

  /**
   * Delete a portal.
   */
  delete(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<boolean>> {
    return this.client.delete(MsAdminApiEndPoints.portals.get(id), options);
  }

  /**
   * save portal theme settings
   */
  saveThemeSettings<T>(portalId: string, data: T, options?: AxiosRequestConfig) {
    return this.client.post<PortalEntity[], MsApiResponse<T>>(
      MsAdminApiEndPoints.portals.themeSettings(portalId),
      data,
      options
    );
  }

  /**
   * Retrieve all portals for a brand
   */
  getAllByBrandId(brandId: string, searchParams?: SearchParams, options?: AxiosRequestConfig) {
    return this.client.get<PortalEntity[], MsApiResponse<PagedDataSource<PortalEntity>>>(
      MsAdminApiEndPoints.portals.getByBrand(brandId),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Update a portal's details.
   */
  updateLogo(id: string, payload: PortalUpdatePayload, options?: AxiosRequestConfig<PortalUpdatePayload>) {
    if (!id) throw new Error('missing id in payload');
    return this.client.patch<PortalEntity, MsApiResponse<PortalEntity>, PortalUpdatePayload>(
      MsAdminApiEndPoints.portals.logo(id),
      payload,
      options
    );
  }

  /**
   * upload portal logos
   */
  async uploadPortalLogos(portalId: string, payloads: FilePayload[]) {
    const promises = payloads.map(payload => {
      return this.client.post<string, MsApiResponse<boolean>>(
        MsAdminApiEndPoints.portals.portalLogo(portalId),
        { ...payload },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    });

    await Promise.all(promises);

    return true;
  }

  /**
   * delete merchant logos
   */
  async deletePortalLogos(portalId: string, logoIds: string[], options?: AxiosRequestConfig<string>) {
    const promises = logoIds.map(logoId => {
      return this.client.delete<string, MsApiResponse<boolean>>(
        MsAdminApiEndPoints.portals.portalLogoDelete(portalId, logoId)
      );
    });

    await Promise.all(promises);

    return true;
  }

  /**
   * upload single logo
   */
  async uploadPortalLogo(portalId: string, payloads: FilePayload) {
    const flag = await this.uploadPortalLogos(portalId, [payloads]);
    return flag;
  }

  /**
   * get portal theme settings
   */
  getThemeSettings<T>(portalId: string, options?: AxiosRequestConfig) {
    return this.client.get<PortalEntity, MsApiResponse<T>>(
      MsAdminApiEndPoints.portals.themeSettings(portalId),
      options
    );
  }
}
