import type { AxiosRequestConfig } from 'axios';

import type {
  AxiosResponseWithError,
  FilePayload,
  MsApiResponse,
  PagedDataSource,
  SearchParams,
} from '../../types/api';
import type { BrandCreatePayload, BrandEntity, BrandUpdatePayload } from '../../types/brands';
import type { CrudResource, GetResource } from '../base';
import BaseResource from '../base';
import { MsAdminApiEndPoints } from './endPoints';

export class BrandsResource extends BaseResource implements GetResource<BrandEntity>, CrudResource<BrandEntity> {
  /**
   * Retrieves a brand by matching field.
   */
  getBy(
    searchParams?: Partial<BrandEntity>,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<BrandEntity>> {
    throw new Error('Method not implemented.');
  }

  /**
   * Retrieves a brand by brand id.
   */
  get(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<BrandEntity>> {
    return this.client.get<BrandEntity, MsApiResponse<BrandEntity>>(MsAdminApiEndPoints.brands.get(id), options);
  }

  /**
   * Retrieve all brands.
   */
  list(
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<PagedDataSource<BrandEntity>>> {
    return this.client.get<BrandEntity[], MsApiResponse<PagedDataSource<BrandEntity>>>(
      MsAdminApiEndPoints.brands.list(),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Create a brand.
   */
  create(
    payload: BrandCreatePayload,
    options?: AxiosRequestConfig<BrandCreatePayload>
  ): Promise<AxiosResponseWithError<BrandEntity>> {
    return this.client.post<BrandEntity, MsApiResponse<BrandEntity>, BrandCreatePayload>(
      MsAdminApiEndPoints.brands.list(),
      payload,
      options
    );
  }

  /**
   * Retrieves a brand by slug.
   */
  getBySlug(slug: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<BrandEntity>> {
    return this.client.get<BrandEntity, MsApiResponse<BrandEntity>>(
      MsAdminApiEndPoints.brands.getBySlug(slug),
      options
    );
  }

  /**
   * Update a brand's details.
   */
  update(
    id: string,
    payload: BrandUpdatePayload,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<BrandEntity>> {
    return this.client.patch<BrandEntity, MsApiResponse<BrandEntity>, BrandUpdatePayload>(
      MsAdminApiEndPoints.brands.get(id),
      payload,
      options
    );
  }

  /**
   * Delete a brand.
   */
  delete(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<boolean>> {
    return this.client.delete(MsAdminApiEndPoints.brands.get(id), options);
  }

  /**
   * Retrieve all brands for a merchant
   */
  getAllByMerchantId(merchantId: string, searchParams?: SearchParams, options?: AxiosRequestConfig) {
    return this.client.get<BrandEntity[], MsApiResponse<PagedDataSource<BrandEntity>>>(
      MsAdminApiEndPoints.brands.getByMerchant(merchantId),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * upload a brand's logo
   */
  async uploadLogo(brandId: string, payload: FilePayload, options?: AxiosRequestConfig) {
    payload.id = payload.id || payload.file.name;

    await this.client.post<string, MsApiResponse<string>>(
      MsAdminApiEndPoints.brands.logo(brandId),
      { ...payload, id: payload.id },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return payload.id;
  }

  /**
   * delete brand logo
   */
  async deleteLogo(brandId: string, options?: AxiosRequestConfig<string>) {
    return this.client.delete<string, MsApiResponse<boolean>>(MsAdminApiEndPoints.brands.logo(brandId), options);
  }
}
