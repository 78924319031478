import type { InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { isServer } from '../client';
import { AppIds } from '../constants/app-ids';
import { createBearerFromToken } from '../utils/auth';
import { localStore } from '../utils/storage';

/**
 * Adds an Authorization header with a Bearer token to requests if the authentication cookie is available
 */
export async function portalAuthInterceptor<T = unknown>(config: InternalAxiosRequestConfig<T>) {
  const appId = AppIds.PORTALS;
  // Add the x-app-id
  config.headers['x-app-id'] = appId;

  // Add the x-app-sub-id header
  let slug = localStore ? localStore.get('portal-slug') : '';

  if (isServer) {
    const { cookies, headers } = await import('next/headers');

    const cookieStore = cookies();

    slug = cookieStore.get('slug')?.value;

    const _appSubId = cookieStore.get(`portal-${slug}-id`)?.value;

    config.headers['x-app-sub-id'] = _appSubId;

    let token = cookieStore.get(`${appId}-${_appSubId}-authentication`)?.value;

    // get token from request header of cookie is missing
    if (!token) {
      // @ts-ignore
      token = headers['Authorization']?.split(' ')[1];
    }

    if (token) {
      config.headers['Authorization'] = createBearerFromToken(token);
    }
  } else {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

    const appSubId = Cookies.get(`portal-${slug}-id`);
    config.headers['x-app-sub-id'] = appSubId;

    if (token) {
      config.headers['Authorization'] = createBearerFromToken(token);
    }
  }

  return config;
}

/**
 * Adds an Authorization header with a Bearer token to requests if the authentication cookie is available
 */
export async function internalAdminAuthInterceptor<T = unknown>(config: InternalAxiosRequestConfig<T>) {
  const appId = AppIds.INTERNAL_ADMIN;
  // Add the x-app-id header
  config.headers['x-app-id'] = AppIds.INTERNAL_ADMIN;

  if (isServer) {
    const { cookies } = await import('next/headers');
    const token = cookies().get(`${appId}-null-authentication`)?.value;

    if (token) {
      config.headers['Authorization'] = createBearerFromToken(token);
    }
  } else {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

    if (token) {
      config.headers['Authorization'] = createBearerFromToken(token);
    }
  }

  return config;
}

/**
 * Adds an Authorization header with a Bearer token to requests if the authentication cookie is available
 */
export async function merchantAdminAuthInterceptor<T = unknown>(config: InternalAxiosRequestConfig<T>) {
  const appId = AppIds.MERCHANT_ADMIN;
  // Add the x-app-id header
  config.headers['x-app-id'] = AppIds.MERCHANT_ADMIN;

  if (isServer) {
    const { cookies } = await import('next/headers');
    const token = cookies().get(`${appId}-null-authentication`)?.value;

    if (token) {
      config.headers['Authorization'] = createBearerFromToken(token);
    }
  } else {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

    if (token) {
      config.headers['Authorization'] = createBearerFromToken(token);
    }
  }

  return config;
}
