import type { AxiosRequestConfig } from 'axios';

import type {
  EmailTemplateCreatePayload,
  EmailTemplateEntity,
  EmailTemplateUpdatePayloadData,
} from '../../types/emailTemplate';

import type { AxiosResponseWithError, MsApiResponse, PagedDataSource, SearchParams } from '../../types/api';
import type { CrudResource, GetResource } from '../base';
import BaseResource from '../base';
import { MsAdminApiEndPoints } from './endPoints';

export class EmailTemplateResource
  extends BaseResource
  implements GetResource<EmailTemplateEntity>, CrudResource<EmailTemplateEntity>
{
  /**
   * Retrieve all email templates.
   */
  list(
    searchParams?: Partial<SearchParams>,
    options?: AxiosRequestConfig
  ): Promise<MsApiResponse<PagedDataSource<EmailTemplateEntity>>> {
    return this.client.get<EmailTemplateEntity[], MsApiResponse<PagedDataSource<EmailTemplateEntity>>>(
      MsAdminApiEndPoints.emailTemplates.list(),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Retrieves an email template by id.
   */
  get(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<EmailTemplateEntity>> {
    return this.client.get<EmailTemplateEntity, MsApiResponse<EmailTemplateEntity>>(
      MsAdminApiEndPoints.emailTemplates.get(id),
      options
    );
  }

  /**
   * Create an email template.
   */
  create(
    payload: EmailTemplateCreatePayload,
    options?: AxiosRequestConfig<EmailTemplateCreatePayload>
  ): Promise<AxiosResponseWithError<EmailTemplateEntity>> {
    return this.client.post<EmailTemplateEntity, MsApiResponse<EmailTemplateEntity>, EmailTemplateCreatePayload>(
      MsAdminApiEndPoints.emailTemplates.create(),
      payload,
      options
    );
  }

  /**
   * Update an email template
   */
  update(
    id: string,
    payload: EmailTemplateUpdatePayloadData,
    options?: AxiosRequestConfig<EmailTemplateUpdatePayloadData>
  ): Promise<AxiosResponseWithError<EmailTemplateEntity>> {
    return this.client.patch<EmailTemplateEntity, MsApiResponse<EmailTemplateEntity>, EmailTemplateUpdatePayloadData>(
      MsAdminApiEndPoints.emailTemplates.update(id),
      payload,
      options
    );
  }

  /**
   * Delete an email template
   */
  delete(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<boolean>> {
    return this.client.delete(MsAdminApiEndPoints.emailTemplates.delete(id), options);
  }
}
