import type { AxiosRequestConfig } from 'axios';
import type { AxiosResponseWithError, FilePayload, MsApiResponse, PagedDataSource } from '../../types/api';
import type {
  UserCreatePayload,
  UserEntity,
  UserProfileEntity,
  UserUpdatePasswordPayload,
  UserUpdatePayload,
} from '../../types/users';
import BaseResource from '../base';
import { MsAdminApiEndPoints } from './endPoints';

export type AdminResetPasswordPayload = {
  password: string;
  resetToken: string;
};

export class AdminUsersResource extends BaseResource {
  /**
   * Retrieve a user.
   */
  get(id: string, options?: AxiosRequestConfig) {
    return this.client.get<UserEntity, AxiosResponseWithError<UserEntity>>(MsAdminApiEndPoints.users.get(id), options);
  }

  /**
   * Retrieve a user by matching a property.
   */
  getBy(search: Partial<UserEntity>, options?: AxiosRequestConfig) {
    return this.client.get<UserEntity, AxiosResponseWithError<UserEntity>>(MsAdminApiEndPoints.users.getBy(search), {
      params: search,
      ...options,
    });
  }

  /**
   * Create a user.
   */
  create(payload: UserCreatePayload, options?: AxiosRequestConfig<UserEntity>) {
    return this.client.post<UserEntity, AxiosResponseWithError<UserEntity>>(
      MsAdminApiEndPoints.users.list(),
      payload,
      options
    );
  }

  /**
   * Update a user's details.
   */
  update(
    id: string,
    payload: UserUpdatePayload,
    options?: AxiosRequestConfig<UserUpdatePayload>
  ): Promise<AxiosResponseWithError<UserEntity>> {
    return this.client.patch<UserEntity, AxiosResponseWithError<UserEntity>, UserUpdatePayload>(
      MsAdminApiEndPoints.users.get(id),
      payload,
      options
    );
  }

  /**
   * Delete a user.
   */
  delete(id: string, options?: AxiosRequestConfig) {
    return this.client.delete<null, AxiosResponseWithError<null>>(MsAdminApiEndPoints.users.delete(id), options);
  }

  /**
   * Restore a user.
   */
  restore(id: string, options?: AxiosRequestConfig) {
    return this.client.post<null, AxiosResponseWithError<null>>(MsAdminApiEndPoints.users.restore(id), options);
  }

  /**
   * Delete a user.
   */
  hardDelete(id: string, options?: AxiosRequestConfig) {
    return this.client.delete<null, AxiosResponseWithError<null>>(MsAdminApiEndPoints.users.delete(id), options);
  }

  /**
   * Retrieve all users.
   */
  list(searchParams?: unknown, options?: AxiosRequestConfig<UserEntity[]>) {
    return this.client.get<UserEntity[], MsApiResponse<PagedDataSource<UserEntity>>>(MsAdminApiEndPoints.users.list(), {
      params: searchParams,
      ...options,
    });
  }

  /**
   * Reset the password of an admin user using their reset password token.
   * You must generate a reset password token first for the user using the {@link sendResetPasswordToken} method,
   */
  resetPassword(payload: AdminResetPasswordPayload, options?: AxiosRequestConfig<AdminResetPasswordPayload>) {
    return this.client.post<null, AxiosResponseWithError<null>, AxiosRequestConfig<AdminResetPasswordPayload>>(
      MsAdminApiEndPoints.users.resetPassword(),
      {
        data: payload,
        ...options,
      }
    );
  }

  /**
   * Update a user's profile details.
   */
  updateUserProfile(payload: UserProfileEntity, options?: AxiosRequestConfig<UserProfileEntity>) {
    return this.client.patch<
      UserProfileEntity,
      AxiosResponseWithError<UserProfileEntity>,
      AxiosRequestConfig<UserProfileEntity>
    >(MsAdminApiEndPoints.users.profile(), {
      ...payload,
      ...options,
    });
  }

  /**
   * upload a user's profile image.
   */
  async uploadUserProfileImage(userId: string, payload: FilePayload) {
    const fileId = payload.file.name;

    await this.client.post<string, MsApiResponse<boolean>>(
      MsAdminApiEndPoints.users.profileImage(userId),
      { ...payload, id: fileId },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return fileId;
  }

  /**
   * delete user's profile image.
   */
  async deleteProfileImage(userId: string, options?: AxiosRequestConfig<string>) {
    return this.client.delete<string, MsApiResponse<boolean>>(MsAdminApiEndPoints.users.profileImage(userId), options);
  }

  /**
   * update signed-in user's password
   */
  async updatePassword(body: UserUpdatePasswordPayload, options?: AxiosRequestConfig<string>) {
    return this.client.post<string, MsApiResponse<boolean>>(
      MsAdminApiEndPoints.users.updatePassword(),
      { ...body },
      options
    );
  }
}
