import type { InternalAxiosRequestConfig } from 'axios';
import { sessionStore } from '../utils/storage';

/**
 * Adds a Browser session id header to requests if session is saved in session storage is available
 */
export async function sessionIdInterceptor<T = unknown>(config: InternalAxiosRequestConfig<T>) {
  const sessionId = sessionStore ? sessionStore.get('sessionId') : null;

  if (sessionId) {
    config.headers['x-session-id'] = sessionId;
  }
  return config;
}
