import type { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import type { BuyerBusinessSignupResponse } from '../types/buyers';

import type { MsApiResponse } from '../types/api';
import type {
  BuyerBusinessSignupPayload,
  BuyerUserSignupPayload,
  ForgotPasswordEntity,
  UpdateUserPasswordPayload,
  UserEmailTokenVerificationPayload,
  UserForgotPasswordPayload,
  UserResetPasswordPayload,
  UserSendSignupConfirmationPayload,
  UserSigninPayload,
  UserSignupPayload,
} from '../types/auth';
import type { UserApiKeyEntity, UserEntity } from '../types/users';
import BaseResource from './base';
import { MsApiEndPoints } from './endPoints';

export class AuthResource extends BaseResource {
  /**
   * Signup for normal users (for internal admin)
   */
  signup(payload: UserSignupPayload, options?: AxiosRequestConfig<UserSignupPayload>) {
    return this.client.post<UserEntity, MsApiResponse<UserEntity>, UserSignupPayload>(
      MsApiEndPoints.auth.signup(),
      payload,
      options
    );
  }

  /**
   * Authenticate a user using their login credentials.
   * If the customer is authenticated successfully a cookie is automatically attached to subsequent requests.
   */
  signin(payload: UserSigninPayload, options?: AxiosRequestConfig<UserSigninPayload>) {
    return this.client.post<UserEntity, MsApiResponse<UserEntity>, UserSigninPayload>(
      MsApiEndPoints.auth.signin(),
      payload,
      options
    );
  }

  /**
   * Log out the customer and remove their authentication session.
   */
  signout(options?: AxiosRequestConfig<UserSignupPayload>) {
    return this.client.post<void, MsApiResponse<void>>(MsApiEndPoints.auth.signout(), null, options);
  }

  /**
   * Check if the email is already used by another registered customer.
   */
  exists(email: string, options?: AxiosRequestConfig<UserSignupPayload>) {
    return this.client.post<void, MsApiResponse<void>>(MsApiEndPoints.auth.exists(), { email }, options);
  }

  /**
   * Refresh auth tokens
   */
  refreshToken(options?: AxiosRequestConfig<UserSignupPayload>) {
    return this.client.post<void, MsApiResponse<void>>(MsApiEndPoints.auth.refreshTokens(), options);
  }

  /**
   * request a resetToken
   */
  // forgotPassword(email: string, options?: AxiosRequestConfig<UserForgotPasswordPayload>) {
  //   return this.client.post<void, MsApiResponse<void>>(MsApiEndPoints.auth.ForgotPassword, { email }, options);
  // }

  forgotPassword(payload: UserForgotPasswordPayload, options?: AxiosRequestConfig<UserForgotPasswordPayload>) {
    return this.client.post<ForgotPasswordEntity, MsApiResponse<ForgotPasswordEntity>>(
      MsApiEndPoints.auth.forgotPassword(),
      payload,
      options
    );
  }

  /**
   * Reset a user's password (requires a resetToken)
   */
  resetPassword(payload: UserResetPasswordPayload, options?: AxiosRequestConfig<UserResetPasswordPayload>) {
    return this.client.post<void, MsApiResponse<void>>(MsApiEndPoints.auth.resetPassword(), payload, options);
  }

  /**
   * Check if the current user has an access token and if the token is still valid.
   */
  isAuthenticated() {
    // The MS backend will set an auth cookie that indicated when the access token expires
    const authCookie = Cookies.get('auth');
    if (!authCookie) return false;

    const authCookieObj = JSON.parse(authCookie);

    const isExpired = authCookieObj.exp - authCookieObj.iat < 0;
    return !isExpired;
  }

  // ----- Buyers Signup

  /**
   * Signup for a new Buyer Business
   */
  signupBuyerBusiness(payload: BuyerBusinessSignupPayload, options?: AxiosRequestConfig<BuyerBusinessSignupPayload>) {
    return this.client.post<
      BuyerBusinessSignupResponse,
      MsApiResponse<BuyerBusinessSignupResponse>,
      BuyerBusinessSignupPayload
    >(MsApiEndPoints.auth.signupBuyerBusiness(), payload, options);
  }

  /**
   * Signup for a new Buyer User
   */
  signupBuyerUser(payload: BuyerUserSignupPayload, options?: AxiosRequestConfig<BuyerUserSignupPayload>) {
    return this.client.post<UserEntity, MsApiResponse<UserEntity>, BuyerUserSignupPayload>(
      MsApiEndPoints.auth.signupBuyerUser(),
      payload,
      options
    );
  }

  /**
   * Update logged in users password
   */
  updatePassword(payload: UpdateUserPasswordPayload, options?: AxiosRequestConfig<UpdateUserPasswordPayload>) {
    return this.client.post<boolean, MsApiResponse<boolean>>(MsApiEndPoints.auth.updatePassword(), payload, options);
  }

  /**
   * Email token verification
   */
  emailTokenVerification(
    payload: UserEmailTokenVerificationPayload,
    options?: AxiosRequestConfig<UserEmailTokenVerificationPayload>
  ) {
    return this.client.post<boolean, MsApiResponse<boolean>>(
      MsApiEndPoints.auth.tokenEmailVerification(),
      payload,
      options
    );
  }

  /**
   * Send email verification
   */
  sendSignupConfirmation(
    payload: UserSendSignupConfirmationPayload,
    options?: AxiosRequestConfig<UserSendSignupConfirmationPayload>
  ) {
    return this.client.post<boolean, MsApiResponse<boolean>>(
      MsApiEndPoints.auth.sendSignupConfirmation(),
      payload,
      options
    );
  }

  /**
   * Retrieve user's api-key
   */
  retrieveApiKey(options?: AxiosRequestConfig<UserApiKeyEntity>) {
    return this.client.get<UserApiKeyEntity, MsApiResponse<UserApiKeyEntity>>(
      MsApiEndPoints.auth.retrieveApiKey(),
      options
    );
  }

  /**
   * Generate user's api-key
   */
  generateApiKey(options?: AxiosRequestConfig<UserApiKeyEntity>) {
    return this.client.post<UserApiKeyEntity, MsApiResponse<UserApiKeyEntity>>(
      MsApiEndPoints.auth.generateApiKey(),
      null,
      options
    );
  }

  /**
   * Regenerate user's api-key
   */
  regenerateApiKey(options?: AxiosRequestConfig<UserApiKeyEntity>) {
    return this.client.post<UserApiKeyEntity, MsApiResponse<UserApiKeyEntity>>(
      MsApiEndPoints.auth.regenerateApiKey(),
      null,
      options
    );
  }

  /**
   * Revoke user's api-key
   */
  revokeApiKey(options?: AxiosRequestConfig<void>) {
    return this.client.post<UserApiKeyEntity, MsApiResponse<void>>(MsApiEndPoints.auth.revokeApiKey(), null, options);
  }
}
