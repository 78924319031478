import type { BalanceInfo } from '@wolfejs/ms-ui/Funds/types';
import type { PgFundsBankEntity } from '@wolfejs/perfectgift-api-js-mock/src/entities/fundsBank.entity';
import type { PgPagedDataSource } from '@wolfejs/perfectgift-api-js-mock/src/types/api';
import type { AxiosRequestConfig } from 'axios';
import type { MsApiResponse } from '../types/api';
import type { UserEntity } from '../types/users';
import BaseResource from './base';
import { MsApiEndPoints } from './endPoints';

export class MyAccountResource extends BaseResource {
  /**
   * Account User Details
   */
  userDetails(options?: AxiosRequestConfig) {
    return this.client.get<UserEntity, MsApiResponse<UserEntity>>(MsApiEndPoints.myaccount.get(), options);
  }

  /**
   * Account Permissions
   */
  permissions(options?: AxiosRequestConfig) {
    return this.client.get<UserEntity, MsApiResponse<UserEntity>>(MsApiEndPoints.myaccount.permissions(), options);
  }

  /**
   * get Funds
   */
  getFunds(options?: AxiosRequestConfig) {
    return this.client.get<Partial<BalanceInfo>, MsApiResponse<PgPagedDataSource<PgFundsBankEntity>>>(
      MsApiEndPoints.myaccount.funds(),
      options
    );
  }
}
