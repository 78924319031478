import type { SearchParams } from '../../types/api';
import type { UserEntity } from '../../types/users';
import { createPathWithSearchParams } from '../../utils/createPathWithSearchParams';

const AdminUserEndPoints = {
  // crud endpoints
  list: () => '/users',
  get: (id: string) => `/users/${id}`,
  getBy: (search: Partial<UserEntity>) => {
    return createPathWithSearchParams('/users', search);
  },
  delete: (id: string) => `/users/${id}`,
  restore: (id: string) => `/users/${id}/restore`,

  // auth endpoints
  resetPassword: () => `/users/reset-password`,
  updatePassword: () => `/users/update-password`,

  // user profile endpoints
  profile: (id?: string) => {
    if (id) return `/users/profile/${id}`;
    return '/users/profile';
  },
  profileImage: (id: string) => `/users/${id}/profile-image`,
};

const AdminMerchantEndPoints = {
  list: () => '/merchants',
  get: (idOrName: string) => `/merchants/${idOrName}`,
  getByBusinessId: (id: string) => `/merchants/business/${id}`,
  getBySlug: (slug: string) => `/merchants/slug/${slug}`,
  existsByEmail: (email: string) => `/merchants/email/${email}`,
  listUsers: (id: string, search?: SearchParams) => createPathWithSearchParams(`/merchants/${id}/users`, search),
  listBuyers: (id: string, search?: SearchParams) => createPathWithSearchParams(`/buyers/merchant/${id}`, search),
  linkUserToBusiness: () => `/users/user-business-mapping`,

  // user profile endpoints
  profile: (id: string) => `/merchants/profile/${id}`,
  logo: (id?: string) => `/merchants/${id}/logo`,

  buyerSettings: (merchantId: string, buyerBusinessId: string) =>
    `/merchants/buyer-settings/${merchantId}/${buyerBusinessId}`,
};

const AdminBrandEndPoints = {
  list: () => '/brands',
  get: (id: string) => `/brands/${id}`,
  getBySlug: (slug: string) => `/brands/slug/${slug}`,
  getAllPortals: () => '/brands/portals',
  getByMerchant: (merchantId: string) => `/brands/merchant/${merchantId}`,
  logo: (id?: string) => `/brands/${id}/logo`,
};

const AdminPortalEndPoints = {
  list: () => '/portals',
  get: (id: string) => `/portals/${id}`,
  getByBrand: (brandId: string) => `/portals/brand/${brandId}`,

  themeSettings: (id: string) => `/portals/${id}/theme-settings`,
  logo: (id: string) => `/portals/${id}/logo`,
  portalLogo: (id: string) => `/portals/${id}/portal-logo`,
  portalLogoDelete: (id: string, logId: string) => `/portals/${id}/portal-logo/${logId}`,
};

const AdminBuyerEndPoints = {
  list: () => '/buyers',
  listUsers: (id: string, search?: SearchParams) => createPathWithSearchParams(`/buyers/${id}/users`, search),
  linkUserToBusiness: () => `/users/user-business-mapping`,
  update: (id: string) => `/buyers/${id}`,
  updateByBusinessId: (id: string) => `/buyers/business/${id}`,
  get: (id: string) => `/buyers/${id}`,
  getBySlug: (id: string) => `/buyers/${id}`,
  getByMerchantId: (merchantId: string) => `/buyers/merchant/buyers/${merchantId}`,
  getByBusinessId: (businessId: string) => `/buyers/business/${businessId}`,
  getAccountManager: () => `/buyers/account-manager`,
};

const AdminEmailTemplateEndPoints = {
  list: () => '/email-templates',
  get: (id: string) => `/email-templates/${id}`,
  getBySlug: (id: string) => `/email-templates/${id}`,
  create: () => `/email-templates`,
  update: (id: string) => `/email-templates/${id}`,
  delete: (id: string) => `/email-templates/${id}`,
};

const AdminEmailAssetEndPoints = {
  list: () => '/email-assets',
  upload: () => `/email-assets`,
  delete: (id: string) => `/email-assets/${id}`,
};

export const MsAdminApiEndPoints = {
  users: AdminUserEndPoints,
  merchants: AdminMerchantEndPoints,
  brands: AdminBrandEndPoints,
  portals: AdminPortalEndPoints,
  buyers: AdminBuyerEndPoints,
  emailTemplates: AdminEmailTemplateEndPoints,
  emailAssets: AdminEmailAssetEndPoints,
};
