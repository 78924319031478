import type { InternalAxiosRequestConfig } from 'axios';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { refreshAccessTokenMemoized } from '../client';
import type { AccessTokenInfo } from '../types/api';
import type { UserEntity } from '../types/users';

/**
 * Intercept unauthorized responses and try to get a new accessToken using the refreshToken.
 */
export async function refreshTokenInterceptor(error: Error) {
  if (error instanceof AxiosError) {
    const config = error.config as InternalAxiosRequestConfig & { sent?: boolean };

    // const authCookie = Cookies.get('auth');
    const authCookie = Cookies.get('auth');
    const userCookie = Cookies.get('user');

    let userFromCookie: Partial<UserEntity> = {};
    if (userCookie) {
      userFromCookie = JSON.parse(userCookie) as UserEntity;
    }

    // stop here if we don't have an auth cookie
    // the authCookie is not the access token and is not the refresh token
    // it's just a cookie that indicates when the access token expires
    // here we are just using it to see if we should bother with trying to refresh the token or not
    if (!authCookie) return Promise.reject(error);

    // if we have an authCookie check it wasn't expired longer than the refresh token is valid
    const refreshTokenMaxAge = 604800 * 1000;

    const authCookieObj = JSON.parse(authCookie) as AccessTokenInfo;

    const tokenCreationTime = new Date(0);
    tokenCreationTime.setUTCSeconds(authCookieObj.iat);

    const refreshTokenExpireTime = new Date(0);
    refreshTokenExpireTime.setUTCSeconds(authCookieObj.iat);

    refreshTokenExpireTime.setMilliseconds(tokenCreationTime.getMilliseconds() + refreshTokenMaxAge);

    const isExpired = refreshTokenExpireTime.getTime() - tokenCreationTime.getTime() < 0;

    if (isExpired) return Promise.reject(error);

    if ((error.response?.status === 403 || error.response?.status === 401) && !config?.sent) {
      config.sent = true;

      await refreshAccessTokenMemoized(userFromCookie?.id);

      return axios(config);
    }
  }
  return Promise.reject(error);
}
