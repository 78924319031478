import type { AxiosRequestConfig } from 'axios';

import type { AxiosResponseWithError, MsApiResponse } from '../types/api';
import type { PortalEntity } from '../types/portals';
import BaseResource from './base';
import { MsApiEndPoints } from './endPoints';

export class PortalResource extends BaseResource {
  /**
   * Retrieves a portal by portal slug.
   */
  getBySlug(slug: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.get<PortalEntity, MsApiResponse<PortalEntity>>(MsApiEndPoints.portals.bySlug(slug), options);
  }

  /**
   * Retrieves a portal by portal id or slug.
   */
  getByIdOrSlug(idOrSlug: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.get<PortalEntity, MsApiResponse<PortalEntity>>(MsApiEndPoints.portals.bySlug(idOrSlug), options);
  }

  /**
   * get portal theme settings
   */
  getThemeSettings<T>(portalId: string, options?: AxiosRequestConfig) {
    return this.client.get<PortalEntity, MsApiResponse<T>>(MsApiEndPoints.portals.themeSetings(portalId), options);
  }
}
